// Generated by Framer (b12dec4)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VmMkQj_bm"];

const variantClassNames = {VmMkQj_bm: "framer-v-av4334"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VmMkQj_bm", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Octfx", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-av4334", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VmMkQj_bm"} ref={ref} style={{...style}}><SVG className={"framer-1mqefs4"} layout={"position"} layoutDependency={layoutDependency} layoutId={"efD4LWvog-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 64 64\"><path d=\"M 35.878 64 L 35.878 41.933 L 44.534 41.933 L 44.534 41.933 L 44.535 41.933 L 46.331 32.118 L 35.878 32.118 L 35.878 28.646 C 35.878 23.462 37.905 21.466 43.148 21.465 C 43.149 21.465 43.15 21.465 43.151 21.465 C 44.781 21.465 46.093 21.505 46.848 21.584 L 46.848 21.584 C 46.848 21.584 46.849 21.584 46.849 21.584 L 46.849 12.688 C 46.721 12.652 46.576 12.616 46.417 12.581 C 45.02 12.269 42.534 11.979 40.693 11.907 C 40.409 11.896 40.14 11.89 39.892 11.89 C 39.89 11.89 39.887 11.89 39.885 11.89 C 29.196 11.892 24.269 16.959 24.269 27.888 L 24.269 32.118 L 17.672 32.118 L 17.672 32.118 L 17.67 32.118 L 17.67 41.933 L 24.269 41.933 L 24.269 63.289 C 10.332 59.818 0 47.179 0 32.118 C 0 14.38 14.327 0 32 0 C 49.673 0 64 14.38 64 32.118 C 64 48.539 51.721 62.079 35.878 64 Z\" fill=\"rgba(0,0,0,1)\"></path></svg>"} svgContentId={4156712309} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Octfx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Octfx .framer-ee5hgk { display: block; }", ".framer-Octfx .framer-av4334 { height: 64px; overflow: hidden; position: relative; width: 64px; }", ".framer-Octfx .framer-1mqefs4 { flex: none; height: 64px; left: calc(50.00000000000002% - 64px / 2); position: absolute; top: calc(50.00000000000002% - 64px / 2); width: 64px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FrameraoWWU5ukK: React.ComponentType<Props> = withCSS(Component, css, "framer-Octfx") as typeof Component;
export default FrameraoWWU5ukK;

FrameraoWWU5ukK.displayName = "Social Buttons / Facebook";

FrameraoWWU5ukK.defaultProps = {height: 64, width: 64};

addFonts(FrameraoWWU5ukK, [])